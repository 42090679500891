import { useEffect, useMemo, useState } from 'react';
import type { ImportedStoreModalData } from './types';
import { IAddress } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { importSingleStore, setSimilarStores } from '@/services/import.service';
import {
  Button,
  Divider,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Spin,
  Tag,
  Typography,
} from 'antd';
import {
  canImportStore,
  formatDistance,
  formatScore,
  getScoreColor,
} from './methods';
import {
  ImportStorePopconfirmDescription,
  ModalLoadingOverlay,
  StoreAddressAndConflicts,
  StoreConflictItem,
  StoreConflicts,
} from './styles';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { googleKey, refreshDataMessages } from './contants';
import { parseGoogleResult } from '@/utils/address';
import { ReloadOutlined } from '@ant-design/icons';

function ImportedStoreModal({
  data,
  idsOfBrandsToConnectWith,
  scoreWeights,
  onUpdateStoreAddress,
  onImportStore,
  onCancel,
}: {
  data: ImportedStoreModalData | null;
  idsOfBrandsToConnectWith: string[];
  scoreWeights: Record<string, number> | undefined;
  onUpdateStoreAddress: () => void;
  onImportStore: () => void;
  onCancel: () => void;
}) {
  const isModalOpen = useMemo(() => data !== null, [data]);
  const [newAddress, setNewAddress] = useState<IAddress | null>(null);
  const [selectedExistingStoreId, setSelectedExistingStoreId] = useState<
    string | null
  >(null);

  const {
    mutate: handleUpdateStoreAddress,
    isPending: isUpdatingStoreAddress,
  } = useMutation({
    mutationFn: async ({ address }: { address: IAddress }) => {
      if (!data) return;
      const result = await setSimilarStores({
        id: data.id,
        name: data.name,
        /* When using the same address, we won't update the address, only the
        possible conflicting stores */
        address,
      });
      if (!result.status) {
        throw new Error(result.message);
      }
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description:
          'Store address was successfully updated. Check its status in the list to see if it has possible conflicts with other stores.',
      });
      onUpdateStoreAddress();
    },
    onError: (error) => {
      notification.error({ message: 'Error', description: error.message });
    },
  });

  const { mutate: handleImportStore, isPending: isImportingStore } =
    useMutation({
      mutationFn: async ({
        existingStoreId,
      }: {
        existingStoreId: string | undefined;
      }) => {
        if (!data) return;

        const result = await importSingleStore({
          brandIds: idsOfBrandsToConnectWith,
          storeObject: {
            importedStoreId: data.id,
            /* If the store ID is not set, it creates a new store, otherwise, it
            sets the imported store as the one with the given ID */
            storeId: existingStoreId,
          },
        });
        if (!result.status) {
          throw new Error(result.message);
        }
      },
      onSuccess: () => {
        notification.success({
          message: 'Success',
          description: 'Stores were successfully sent to be processed',
        });
        onImportStore();
      },
      onError: (error) => {
        notification.error({ message: 'Error', description: error.message });
      },
    });

  const hasSelectedBrands = idsOfBrandsToConnectWith.length > 0;
  const isLoading = isUpdatingStoreAddress || isImportingStore;
  const isSetAsExistingStoreDisabled =
    !data ||
    !canImportStore(data) ||
    selectedExistingStoreId === null ||
    !hasSelectedBrands ||
    isLoading;
  const isCreateNewStoreDisabled =
    !data || !canImportStore(data) || !hasSelectedBrands || isLoading;

  // Reset the states when the modal is closed
  useEffect(() => {
    if (!isModalOpen) {
      setNewAddress(null);
      setSelectedExistingStoreId(null);
    }
  }, [isModalOpen]);

  return (
    <Modal
      title="Store Details"
      open={isModalOpen}
      width={768}
      footer={null}
      destroyOnClose
      onCancel={onCancel}
    >
      {data !== null && (
        <StoreAddressAndConflicts>
          <div>
            <strong style={{ fontSize: '1rem' }}>Update Store Address</strong>

            <p>
              <strong>Store Name:</strong> {data.name}
            </p>

            {data.address !== undefined ? (
              <>
                <p>
                  Changing the address will update the possible conflicting
                  stores.
                </p>

                <p>
                  <strong>Current Address:</strong>{' '}
                  {data.address.formattedAddress}
                </p>
              </>
            ) : (
              <p>
                Address not found, please search and select an address for the
                store input below so that we can check for conflicting stores in
                our database.
              </p>
            )}

            {newAddress?.formattedAddress && (
              <p>
                <strong>Selected Address:</strong> {newAddress.formattedAddress}
              </p>
            )}

            <ReactGoogleAutocomplete
              apiKey={googleKey}
              style={{ width: '100%' }}
              placeholder="Search address"
              onPlaceSelected={(place) => {
                const address = parseGoogleResult(place);
                setNewAddress(address);
              }}
              options={{ types: ['address'] }}
            />

            <Popconfirm
              title="Update store address?"
              description="Are you sure you want to update the store address?"
              okText="Yes"
              cancelText="No"
              disabled={!newAddress || isLoading}
              onConfirm={() => {
                if (!newAddress) return;
                handleUpdateStoreAddress({ address: newAddress });
              }}
            >
              <Button type="primary" disabled={!newAddress || isLoading}>
                Update Store Address
              </Button>
            </Popconfirm>
          </div>

          <Divider type="vertical" />

          <div>
            <strong style={{ fontSize: '1rem' }}>
              Set as Existing Store or Create New Store
            </strong>

            <Typography.Paragraph
              ellipsis={{ expandable: 'collapsible', defaultExpanded: true }}
            >
              Scores determine how similar the imported store is to the existing
              ones. <strong>Maximum</strong> score is 1.{' '}
              <strong>Minimum</strong> score is 0. Stores are sorted by average
              score, from the best to the worst. Some scores have{' '}
              <strong>different weights</strong> indicated in parentheses.
            </Typography.Paragraph>

            <Popconfirm
              title={refreshDataMessages.title}
              description={refreshDataMessages.description.singular}
              okText="Yes"
              cancelText="No"
              disabled={!data.address || isLoading}
              onConfirm={() => {
                if (!data.address) return;
                handleUpdateStoreAddress({ address: data.address });
              }}
            >
              <Button
                icon={<ReloadOutlined />}
                disabled={!data.address || isLoading}
              >
                {refreshDataMessages.button}
              </Button>
            </Popconfirm>

            <Radio.Group
              value={selectedExistingStoreId}
              onChange={(event) => {
                setSelectedExistingStoreId(event.target.value);
              }}
            >
              <StoreConflicts>
                {data.conflictStores.map((store) => (
                  <Radio key={store.id} value={store.id}>
                    <StoreConflictItem>
                      <span>
                        <strong>ID: </strong>
                        <Typography.Link
                          href={`/store/${store.id}/manage`}
                          target="_blank"
                          ellipsis
                          copyable
                        >
                          {store.id}
                        </Typography.Link>
                      </span>
                      <span>
                        <strong>Name:</strong> {store.name}{' '}
                        <Tag color={getScoreColor(store.scoreByName)}>
                          Score:{' '}
                          {formatScore(store.scoreByName, scoreWeights?.name)}
                        </Tag>
                      </span>

                      <span>
                        <strong>Address:</strong>{' '}
                        {store.address?.formattedAddress ?? 'Unknown'}{' '}
                        <Tag color={getScoreColor(store.scoreByAddress)}>
                          Score:{' '}
                          {formatScore(
                            store.scoreByAddress,
                            scoreWeights?.address,
                          )}
                        </Tag>
                      </span>

                      <span>
                        <strong>Distance:</strong>{' '}
                        {formatDistance(store.calcDistance)}{' '}
                        <Tag color={getScoreColor(store.scoreByDistance)}>
                          Score:{' '}
                          {formatScore(
                            store.scoreByDistance,
                            scoreWeights?.distance,
                          )}
                        </Tag>
                      </span>

                      <span>
                        <strong>Kpi:</strong> {store.kpi?.users ?? 0} Users,{' '}
                        {store.kpi?.brands ?? 0} Brands{' '}
                        <Tag color={getScoreColor(store.scoreByTotalUsers)}>
                          Score:{' '}
                          {formatScore(
                            store.scoreByTotalUsers,
                            scoreWeights?.totalUsers,
                          )}
                        </Tag>
                      </span>

                      <Tag color={getScoreColor(store.averageScore)}>
                        Average Score:{' '}
                        {formatScore(store.averageScore, undefined)}
                      </Tag>
                    </StoreConflictItem>
                  </Radio>
                ))}
              </StoreConflicts>
            </Radio.Group>

            <Typography.Text style={{ marginTop: '0.5rem' }} type="warning">
              <strong>NOTE:</strong> Setting the imported store as one of the
              existing stores OR creating a new one will already connect the
              store to the selected brands.
            </Typography.Text>

            <Popconfirm
              title="Set imported store as existing store?"
              description={() => {
                const conflictingStore = data.conflictStores.find(
                  (store) => store.id === selectedExistingStoreId,
                );
                return (
                  <ImportStorePopconfirmDescription>
                    <p>
                      Are you sure you want to set the imported store as the
                      following existing store?
                    </p>
                    <span>
                      <strong>Name:</strong> {conflictingStore?.name}
                    </span>
                    <span>
                      <strong>Address:</strong>{' '}
                      {conflictingStore?.address?.formattedAddress}
                    </span>
                    <span>
                      <strong>Kpi:</strong> {conflictingStore?.kpi?.users ?? 0}{' '}
                      Users, {conflictingStore?.kpi?.brands ?? 0} Brands
                    </span>
                  </ImportStorePopconfirmDescription>
                );
              }}
              okText="Yes"
              cancelText="No"
              disabled={isSetAsExistingStoreDisabled}
              onConfirm={() => {
                if (!selectedExistingStoreId) return;
                handleImportStore({
                  existingStoreId: selectedExistingStoreId,
                });
              }}
            >
              {/* TODO: Add tooltip for why this button is disabled */}
              <Button type="primary" disabled={isSetAsExistingStoreDisabled}>
                Set as Existing Selected Store
              </Button>
            </Popconfirm>

            <Popconfirm
              title="Create new store?"
              description="Are you sure you want to create a new store?"
              okText="Yes"
              cancelText="No"
              disabled={isCreateNewStoreDisabled}
              onConfirm={() => {
                handleImportStore({ existingStoreId: undefined });
              }}
            >
              {/* TODO: Add tooltip for why this button is disabled */}
              <Button disabled={isCreateNewStoreDisabled}>
                Create New Store
              </Button>
            </Popconfirm>
          </div>
        </StoreAddressAndConflicts>
      )}

      {isLoading && (
        <ModalLoadingOverlay>
          <Spin />
        </ModalLoadingOverlay>
      )}
    </Modal>
  );
}

export default ImportedStoreModal;
